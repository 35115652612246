<template>
  <el-row>
    <el-col :span="4">
      <div class="grid-content bg-purple">
        <span class="sub-title">控制器
          <template v-if="resetCount>0">({{resetCount}})</template>
          <i @click="config.settingVisible=true" class="el-icon-setting sub-icon"></i>
        </span>
        <el-button class="configBtn" type="primary" v-if="!action" @click="startWork">开始工作</el-button>
        <el-button class="configBtn" type="danger" v-else @click="stopWork('手动停止')">停止工作</el-button>
        <div class="sub-title-log">
          <span @dblclick="openLog">运行日志{{logger.index?`(${logger.index})`:''}}<i @click="uploadLog" class="el-icon-upload sub-icon"></i></span>
          <el-radio-group v-model="logger.type" @input="logUpdateText">
            <template v-for="(label,value,index) in logger.typeArray">
              <el-radio :label="value">{{label}}</el-radio>
            </template>
          </el-radio-group>
          <textarea name="" v-model="logger.text" disabled id="logarea" cols="30" rows="10"></textarea>
        </div>
      </div>
    </el-col>
    <el-col :span="10">
      <div class="grid-content">
        <span class="sub-title">任务发送（25）</span>
        <div id="qr-container">
          <div id="qrcode" ref="qrCodeUrl"></div>
        </div>
        <div class="data-description">
          <span>当前消息:&nbsp;&nbsp;</span>
          <span :class="getBackgroundClass(taskSender.process.curMessage)">
              {{this.getMsgDescription(taskSender.process.curMessage, true)}}
          </span>
          <br><span>提示信息:&nbsp;&nbsp;</span>
          <span v-if="taskSender.noticeMsg" class="bg-warning">
              {{taskSender.noticeMsg}}
          </span>
        </div>
        <div class="data-description">
          <span>数据统计</span>
          <el-tag type="warning">待发送{{taskWaitingNum}}</el-tag>
          <el-tag type="success">已发送{{taskSender.statics.taskSentNum}}</el-tag>
          <el-tag type="info">二维码个数{{taskSender.statics.createQrCount}}</el-tag>
        </div>
        <el-divider>已发送任务</el-divider>
        <el-table
            :data="taskSender.storage.sentMesData"
            style="width: 100%">
          <el-table-column
              prop="id"
              label="uuid"
              min-width="70">
          </el-table-column>
          <el-table-column
              prop="part"
              label="完整度"
              min-width="70">
          </el-table-column>
          <el-table-column
              prop="date"
              label="创建时间"
              min-width="70">
          </el-table-column>
          <el-table-column
              prop="status"
              label="状态"
              min-width="70">
          </el-table-column>
        </el-table>
      </div>
    </el-col>
    <el-col :span="10">
      <div class="grid-content">
        <span class="sub-title">任务接收</span>
        <div id="qr-container">
          <div id="qr-reader" style="width: 340px"></div>
        </div>
        <div class="data-description">
          <span>当前信息:&nbsp;&nbsp;</span>
          <span :class="getBackgroundClass(taskReceiver.process.preMessage)">
              {{this.getMsgDescription(taskReceiver.process.preMessage, false)}}
          </span>
          <br><span>提示信息:&nbsp;&nbsp;</span>
          <span v-if="taskReceiver.noticeMsg" class="bg-warning">
              {{taskReceiver.noticeMsg}}
          </span>
        </div>
        <div class="data-description">
          <span>数据统计</span>
          <el-tag type="warning">已接收{{taskReceiver.statics.taskReceiveNum}}</el-tag>
          <el-tag type="success">已上传{{taskReceiver.statics.taskUploadNum}}</el-tag>
          <el-tag type="info">二维码个数{{taskReceiver.statics.qrReceiveNum}}</el-tag>
          <!--          <el-tag type="info">仓库使用{{taskSender.storage.historyMesArr.length}}/{{config.maxHistoryMsgsize}}</el-tag>-->
        </div>
        <input type="hidden" readonly v-model="output"></input>
        <el-divider>已接收任务</el-divider>
        <el-table
            :data="taskReceiver.storage.receiveMsgData"
            style="width: 100%">
          <el-table-column
              prop="id"
              label="uuid"
              min-width="70">
          </el-table-column>
          <el-table-column
              prop="part"
              label="完整度"
              min-width="70">
          </el-table-column>
          <el-table-column
              prop="result"
              label="上传结果"
              min-width="70">
          </el-table-column>
          <el-table-column
              prop="status"
              label="状态"
              min-width="70">
          </el-table-column>
        </el-table>
      </div>
    </el-col>
    <el-dialog title="修改配置" :visible.sync="config.settingVisible" append-to-body center>
      <span class="config-title">二维码尺寸宽(px)</span>
      <el-input v-model="config.qrWidth"  :disabled="action"></el-input>
      <el-divider></el-divider>
      <span class="config-title">二维码尺寸高(px)</span>
      <el-input v-model="config.qrHeight"  :disabled="action"></el-input>
      <el-divider></el-divider>
      <span class="config-title">单个二维码最大字符</span>
      <el-input v-model="config.qrMaxChars"  :disabled="action"></el-input>
    </el-dialog>
  </el-row>

</template>

<script>
import QRCode from 'qrcodejs2'
import { loadScript } from "vue-plugin-load-script";

const getTaskListUrl = '/task/getwaitlist';
const getTaskContentUrl = id=>`/task/getqrcode/${id}`
const putUploadTaskUrl = '/task/executeqrcode'
const putTaskDoneUrl = id=>`/task/reportdone/${id}`
const postTaskLogUrl = '/log/hltasklog'
/*摄像头相关*/
let previousCode = "";
let initPos = 0;
let maxMove = 50;
export default {
  name: "Menu1_HlTaskTerminal",
  data(){
    return {
      config: {
        qrWidth: 300,
        qrHeight: 300,
        qrMaxChars: 50,
        master: false,     //遇到双方同时发起连接, master的一方拥有更高权限
        senderTaskIntervalMillis: 5000,   //间隔毫秒
        receiveTaskIntervalMillis: 500,
        finFlagHoldMillis: 2000,    //结束标志停放时间
        tableMaxSize: 10,
        settingVisible: false,
        qrMoveFrame: 4,     //qr每隔 qrMoveFrame*receiveTaskIntervalMillis 时间变换位置
        resetMillis: 5000, //自动重启时间(接收方为一半时间)
      },
      logger: {
        text: '',
        type: '0',
        typeArray:{
          '0': '所有',
          '1': '发送方',
          '2': '接收方',
          '9': '系统',
        },
        dataArray:{
          '1': [],
          '2': [],
          '9': [],
        },
        maxSize: 300,
        index: null,
      },
      action: false,
      connectId: null,      //连接Id， 一般为当前uuid
      connectSender: false,  //连接发送者, 发送报文的人, false则为连接接受者
      senderBaker: null,
      receiverBaker: null,
      loggerBaker: null,
      output: null,
      resetCount: 0,
      //消息结构
      MessageBlock: {
        id: 'c81ef0bdeaa9ef05c61ec2a196dd220a', //32位uuid
        type: 'text',
        //jwt字符串受config.qrMaxChars影响，在初始化测试中会放入批次信息
        text: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJwbGlkIjoiZHNj...',
        index: 1,   //当前索引
        size: 5     //总共索引数
      },
      //消息类型:
      MSGTYPE:{
        TEXT: 'text',         //正常报文
        ACK: 'ack',                 //响应报文
        FIN: 'fin',                 //结束报文
        ABORT: 'abort'              //中止报文(出现异常)
      },
      taskSender: {
        statics:{
          //统计数据
          taskWaitingNum: 0,
          taskSentNum: 0,
          createQrCount: 0,
        },
        process: {
          //正在处理的数据
          curMessage: {},       //正在发送的消息, 即当前二维码内容
          messageQueue: [],     //待发送消息队列, 即所有qrcode按config.qrMaxChars拆分出的数组
          finStamp: null,       //发出FIN信号后5妙的时间戳,开始新的连接时间戳必须大于此值
        },
        storage: {
          taskUuidWaitArr: [],  //待处理的任务uuid队列
          sentMesData: []       //table显示的历史数据
        },
        intervalTimer: -1,      //定时任务, 检查是否可以开始新连接
        qrCodeData: null,
        noticeMsg: null,
      },
      taskReceiver: {
        statics: {
          taskReceiveNum: 0,
          taskUploadNum: 0,
          qrReceiveNum: 0,
        },
        process: {
          curMessage: {},      //正在读取的消息
          preMessage: {},     //前一台消息, 最新处理过的curMessage会变成preMessage
          lastMessage: {},     //最后一条信息 index===size
          messageQueue: [],    //已收到的消息报文数组
          curMessageLogStamp: null, //记录正在读取消息日志的截止时间，否则会一直重复记录一条的情况
        },
        storage: {
          receiveMsgData:[]     //已收集数据
        },
        intervalTimer: -1,
        frame: 0,
        noticeMsg: null,
        endStatus: false,       //任务接收方可能错过发送方的fin消息,所以在最后一个ack时设置结束信号可以处理下一个消息
      },
    }
  },
  computed:{
    taskWaitingNum:{
      get(){
        return this.taskSender.storage.taskUuidWaitArr.length
      }
    }
  },
  methods: {
    startWork(){
      if(!/^\d+$/.test(this.config.qrWidth)){
        this.$message.error('二维码尺寸宽不是一个有效的数字');
        return false;
      }
      if(!/^\d+$/.test(this.config.qrHeight)){
        this.$message.error('二维码尺寸高不是一个有效的数字');
        return false;
      }
      if(!/^\d+$/.test(this.config.qrMaxChars)){
        this.$message.error('单个二维码最大字符不是一个有效的数字');
        return false;
      }
      let container = document.getElementById('qr-container')
      container.style.height = (parseInt(this.config.qrHeight)+10) + 'px';
      container.style.width = (parseInt(this.config.qrWidth)+10) + 'px';
      if(this.senderBaker == null){
        this.senderBaker = this.g_deepClone(this.taskSender)
        this.receiverBaker = this.g_deepClone(this.taskReceiver)
        this.loggerBaker = this.g_deepClone(this.logger)
      }else{
        this.taskSender = this.g_deepClone(this.senderBaker)
        this.taskReceiver = this.g_deepClone(this.receiverBaker)
        this.logger = this.g_deepClone(this.loggerBaker)
      }
      this.connectId = null
      this.updateQr()
      this.taskSender.intervalTimer = setInterval(this.taskSenderIntervalTimer, this.config.senderTaskIntervalMillis)
      this.taskReceiver.intervalTimer = setInterval(this.taskReceiverIntervalTimer, this.config.receiveTaskIntervalMillis)
      this.action = true
      this.addSysLog('控制器开始工作')
    },
    stopWork(msg){
      this.action = false
      clearInterval(this.taskSender.intervalTimer)
      clearInterval(this.taskReceiver.intervalTimer)
      this.addSysLog(`控制器结束工作${msg?('<'+msg+'>'):''}`)
      this.uploadLog(true)
    },
    checkCreateConnect(isFirstSender) {
      if (!this.connectId) return true
      if(!isFirstSender && !this.config.master){
        //接收方收到新任务,同时自身权限低
        this.closeConnect('依照权限比较')
        return true
      }
      //是否达到了结束时间
      let finStamp = this.taskSender.process.finStamp
      if(finStamp){
        const stamp = new Date().getTime();
        if(stamp >= finStamp){
          this.closeConnect('依照结束时间')
          return true
        }
      }
      //接收方是否设置了结束标志
      if(!isFirstSender && this.taskReceiver.endStatus){
        this.closeConnect('依照结束标志')
        return true
      }
      return false
    },
    //开始新的连接 isFirstSender-调用方是不是消息的发起方
    createConnect(id, isFirstSender){
      if(!this.checkCreateConnect(isFirstSender)) return false
      this.connectId = id
      this.taskSender.process = {
        curMessage: null,
        messageQueue: isFirstSender?null:[],
        finStamp: null,
      }
      if(isFirstSender){
        this.fetchTaskContent()
      }else{
        this.taskReceiver.process.messageQueue = []
      }
      this.taskReceiver.endStatus = false
      this.connectSender = isFirstSender
      this.addSysLog(`创建新的连接,作为任务的${isFirstSender?'发起方':'接收方'},任务ID<${id}>`)
      return true
    },
    closeConnect(msg){
      this.addSysLog(`关闭连接<${msg?msg:''}>\t${this.connectId}`)
      this.connectId = null
    },
    taskSenderIntervalTimer(){
      //任务列表为空且当时不忙请求新的任务列表
      if(this.taskSender.storage.taskUuidWaitArr.length === 0 && this.checkCreateConnect(true)){
        this.fetchTaskUuidList()
        return ;
      }
      //任务列表不为空尝试开始新的连接
      if(this.taskSender.storage.taskUuidWaitArr.length > 0){
        let uuid = this.taskSender.storage.taskUuidWaitArr[0]
        if(!uuid || this.createConnect(uuid, true)){
          this.taskSender.storage.taskUuidWaitArr = this.taskSender.storage.taskUuidWaitArr.slice(1)
        }
      }
    },
    taskReceiverIntervalTimer(){
      ++this.taskReceiver.frame;
      if(this.taskReceiver.frame%this.config.qrMoveFrame===0){
        this.moveQr()
      }
      //add more frame work
      if(this.taskReceiver.frame === 10000){
        this.taskReceiver.frame = 0;
      }
      if(this.output && this.output.length>0){
        let obj = JSON.parse(this.output)
        if(typeof obj !== 'object'){
          let warnStr = `收到一个非JSON数据<${typeof obj}>:${this.output}`
          this.taskReceiver.noticeMsg = warnStr
          this.addSysLog(warnStr)
          this.output = null
          return false
        }
        this.taskReceiver.process.preMessage = obj
        this.taskReceiver.process.curMessage = obj
        this.taskReceiver.process.curMessageLogStamp = new Date().getTime()+this.config.finFlagHoldMillis;
        this.output = null
        ++this.taskReceiver.statics.qrReceiveNum
      }
      this.processReceiveMsg()
    },
    processReceiveMsg(){
      const receiveMsg = this.taskReceiver.process.curMessage
      if(!receiveMsg || Object.keys(receiveMsg).length===0) return false
      if(new Date().getTime()<this.taskReceiver.process.curMessageLogStamp){
        this.addReceiverLog(`处理消息\t${JSON.stringify(receiveMsg)}`)
      }
      if(receiveMsg.type === this.MSGTYPE.TEXT){
        if(receiveMsg.index === 1){
          //新的连接
          if(!this.createConnect(receiveMsg.id, false)) return false
          this.taskReceiver.storage.receiveMsgData.unshift({
            id: receiveMsg.id,
            part: receiveMsg.index+'/'+receiveMsg.size,
            date: new Date().toLocaleTimeString(),
            status: '接收中'
          })
        }
        if(this.connectId !== receiveMsg.id){
          this.taskReceiver.noticeMsg = '两次连接ID不一致'
          this.setAbortMsg()
          return false
        }
        this.taskReceiver.process.messageQueue.push(receiveMsg.text)
        if(this.taskReceiver.process.messageQueue.length !== receiveMsg.index){
          this.taskReceiver.noticeMsg = '报文获取长度不一致'
          this.setAbortMsg()
          return false
        }
        this.taskReceiver.storage.receiveMsgData =  this.taskReceiver.storage.receiveMsgData.map(msgTableDate=>
            msgTableDate.id === receiveMsg.id?{...msgTableDate, part: receiveMsg.index+'/'+receiveMsg.size}: msgTableDate)
        if(receiveMsg.index === receiveMsg.size){
          this.taskReceiver.process.lastMessage = receiveMsg
          ++this.taskReceiver.statics.taskReceiveNum
          this.uploadTaskContent()
        }else{
          this.taskSender.process.curMessage = {
            id: receiveMsg.id,
            type: this.MSGTYPE.ACK,
            text: '',
            index: receiveMsg.index,
            size: receiveMsg.size,
          }
          this.updateQr()
        }
      }else if(receiveMsg.type === this.MSGTYPE.ACK){
        const curSendMsg =  this.taskSender.process.curMessage
        if(curSendMsg.id !== receiveMsg.id
            || curSendMsg.index !== receiveMsg.index
            || curSendMsg.size !== receiveMsg.size){
          let detailMsg = null
          if(curSendMsg.id !== receiveMsg.id){
            detailMsg = `ID不一致 当前<${curSendMsg.id}> 收到<${receiveMsg.id}>`
          }else{
            detailMsg = `索引不一致 当前<${curSendMsg.index}/${curSendMsg.size}> 收到<${receiveMsg.index}/${receiveMsg.size}>`
          }
          this.taskReceiver.noticeMsg = `收到的回复消息与正在发送的不一致:${detailMsg}`
          this.addSysLog(this.taskReceiver.noticeMsg)
          this.setAbortMsg(true)
          return false
        }
        if(curSendMsg.index < curSendMsg.size){
          this.taskSender.process.curMessage = {
            ...curSendMsg,
            text: this.taskSender.process.messageQueue[curSendMsg.index],
            index: curSendMsg.index+1
          }
          this.taskSender.storage.sentMesData = this.taskSender.storage.sentMesData.map(msgData=>
              msgData.id===curSendMsg.id?{...msgData, index: curSendMsg.index+1+'/'+curSendMsg.size}:msgData)
        }else if(curSendMsg.index === curSendMsg.size){
          this.taskSender.process.curMessage = {
            id: curSendMsg.id,
            type: this.MSGTYPE.FIN,
          }
          if(receiveMsg.text === 'success'){
            this.reportTaskDone(curSendMsg.id)
          }else{
            this.addSysLog('对方执行失败，所以拒绝回复任务')
            this.taskSender.storage.sentMesData = this.taskSender.storage.sentMesData.map(msgData=>
                msgData.id===curSendMsg.id?{...msgData, status: '失败不回复'}:msgData)
          }
          //设置延迟断开
          this.taskSender.process.finStamp = new Date().getTime() + this.config.finFlagHoldMillis
        }
        this.updateQr()
        ++this.taskSender.statics.taskSentNum
      }else if(receiveMsg.type === this.MSGTYPE.FIN){
        //立刻断开连接
        if(this.taskReceiver.endStatus){
          this.closeConnect('收到FIN')
        }
      }else if(receiveMsg.type === this.MSGTYPE.ABORT){
        const reset = receiveMsg.text === 'reset'
        const resetMsg = reset?`,系统将于${Math.floor(this.config.resetMillis/2000)}秒后重启`:''
        this.stopWork()
        this.$alert('收到终止信号,系统停止工作'+resetMsg)
        if(reset){
          this.resetCount++;
          setTimeout(this.startWork, this.config.resetMillis/2)
        }
      }else{
        this.$message.error(`无法解析该消息:${receiveMsg}`);
      }
      this.taskReceiver.process.curMessage = null
      return true
    },
    fetchTaskUuidList(){
      this.taskSender.noticeMsg = '获取任务列表...'
      this.addSysLog('开始获取任务列表')
      this.$axios.get(getTaskListUrl).then(res=>{
        this.taskSender.noticeMsg = ''
        if(this.taskSender.storage.taskUuidWaitArr.length ===0){
          this.taskSender.storage.taskUuidWaitArr = res.data
          this.addSysLog(`获取任务列表结束共${res.data.length}个任务`)
        }
      }).catch(e=>{
        this.addSysLog(`获取任务列表失败，错误明细\t${e}`)
        this.stopWork('获取任务列表失败')
      })
    },
    fetchTaskContent(){
      this.taskSender.noticeMsg = '获取任务二维码...'
      this.addSysLog(`开始获取任务Jwt\t${this.connectId}`)
      this.$axios.get(getTaskContentUrl(this.connectId)).then(res=>{
        this.taskSender.noticeMsg = ''
        if(!this.connectSender) return false
        if(this.taskSender.process.messageQueue) return false
        const encodeText = res.data.encodeContent;
        this.addSysLog(`获取任务Jwt成功,长度${encodeText.length},任务ID<${this.connectId}>`)
        const arr = []
        const maxChars = this.config.qrMaxChars
        const length = Math.floor(encodeText.length/maxChars)+1
        for (let i = 0; i < length; i++) {
          if(i === (length-1)){
            arr.push(encodeText.substring(i*maxChars))
          }else{
            arr.push(encodeText.substring(i*maxChars,(i+1)*maxChars))
          }
        }
        this.taskSender.process.messageQueue = arr
        //创建消息头
        this.taskSender.process.curMessage = {
          id: this.connectId,
          type: this.MSGTYPE.TEXT,
          text: arr[0],
          index: 1,
          size: arr.length
        }
        this.updateQr();
        //加入列表
        this.taskSender.storage.sentMesData.unshift({
          id: this.connectId,
          part: `1/${arr.length}`,
          date: new Date().toLocaleTimeString(),
          status: '发送中'
        })
      }).catch(e=>{
        this.addSysLog(`获取任务Jwt失败\tID<${this.connectId}>\t${e}`)
        this.closeConnect('主动断开，开始下一个任务')
      })
    },
    uploadTaskContent(){
      if(!this.taskReceiver.process.lastMessage || this.taskReceiver.noticeMsg) return false
      const content = this.taskReceiver.process.messageQueue.join('')
      const id = this.taskReceiver.process.lastMessage.id
      this.taskReceiver.storage.receiveMsgData =  this.taskReceiver.storage.receiveMsgData.map(msgTableDate=>
          msgTableDate.id === id?{...msgTableDate, status: '开始执行'}: msgTableDate)
      this.taskReceiver.noticeMsg = '执行任务中...'
      this.addSysLog(`开始执行任务,jwt长度${content.length}`)
      this.$axios.put(putUploadTaskUrl, {encodeContent: content}).then(res=> {
        this.taskReceiver.noticeMsg = ''
        this.addSysLog(`执行任务成功,jwt长度${content.length},返回结果${res.data.status===0?'success':'fail'}`)
        if(res.data.status!==0){
          this.addSysLog(`异常原因:${res.data.msg}`)
        }
        ++this.taskReceiver.statics.taskUploadNum
        const receiveMsg = this.taskReceiver.process.lastMessage
        if(!receiveMsg) return false
        this.taskSender.process.curMessage = {
          id: receiveMsg.id,
          type: this.MSGTYPE.ACK,
          text: (res.data.status===0||res.data.msg==='任务已存在')?'success':'fail',
          index: receiveMsg.index,
          size: receiveMsg.size
        }
        this.updateQr()
        this.taskReceiver.process.lastMessage = null
        this.taskReceiver.endStatus = true
        //修改table数据
        this.taskReceiver.storage.receiveMsgData = this.taskReceiver.storage.receiveMsgData.map(msg=>msg.id === id?
            {...msg, status: '执行结束', result:res.data.status===0?'成功':'失败', name: res.data.name, errorMsg: res.data.msg}:msg)
      }).catch(e=>{
        this.addSysLog(`执行异常\t${e}\t报文:${content}`)
        this.stopWork('任务执行异常')
      })
    },
    reportTaskDone(id){
      this.taskSender.storage.sentMesData = this.taskSender.storage.sentMesData.map(msgData=>
          msgData.id===id?{...msgData, status: '回复任务中'}:msgData)
      this.addSysLog(`开始回复任务\t${id}`)
      this.$axios.put(putTaskDoneUrl(id)).then(()=>{
        this.taskSender.storage.sentMesData = this.taskSender.storage.sentMesData.map(msgData=>
            msgData.id===id?{...msgData, status: '回复结束'}:msgData)
        this.addSysLog(`结束回复任务\t${id}`)
      }).catch(e=>{
        this.addSysLog(`回复任务异常\tID<${id}>\t${e}`)
        this.taskSender.storage.sentMesData = this.taskSender.storage.sentMesData.map(msgData=>
            msgData.id===id?{...msgData, status: '回复异常'}:msgData)
      })
    },
    updateQr(){
      const qrCodeHtml = document.getElementById("qrcode");
      qrCodeHtml.innerHTML = "";
      const curMessage = this.taskSender.process.curMessage
      if(curMessage && Object.keys(curMessage).length>0){
        this.taskSender.qrCodeData = new QRCode(this.$refs.qrCodeUrl, {
          text: JSON.stringify(curMessage), // 需要转换为二维码的内容
          width: this.config.qrWidth,
          height: this.config.qrHeight,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        })
        ++this.taskSender.statics.createQrCount;
        this.addSenderLog(`添加二维码\t${JSON.stringify(curMessage)}`)
      }
    },
    moveQr(){
      const qrCodeHtml = document.getElementById("qrcode")
      let rdm = Math.floor(3 + Math.random()*(maxMove-3));
      let move = 0;
      rdm += initPos;
      rdm = rdm%maxMove;
      if(initPos < rdm){
        move = rdm - initPos;
      }else{
        move =  initPos - rdm;
      }
      initPos = rdm;
      qrCodeHtml.style.marginLeft = `${move}px`
    },
    setAbortMsg(reset){
      this.taskSender.process.curMessage = {
        type: this.MSGTYPE.ABORT,
        text: reset?'reset':'none'
      }
      this.updateQr()
      this.stopWork()
      const resetMsg = reset?`,系统将于${this.config.resetMillis/1000}秒后重启`:''
      this.$alert('发送终止信号,系统停止工作'+resetMsg)
      this.addSysLog('已发送终止信号'+resetMsg)
      if(reset){
        this.resetCount++;
        setTimeout(this.startWork, this.config.resetMillis)
      }
    },
    getMsgDescription(msg, isSender){
      if(!msg || Object.keys(msg).length===0) return '任务为空'
      if(msg.type === this.MSGTYPE.TEXT){
        return `${isSender?"发送正文":"[接收正文]"}（ID:${msg.id} 进度:${msg.index}/${msg.size}）`
      }else if(msg.type === this.MSGTYPE.ACK){
        return `${isSender?"发送回复":"[接收回复]"}（ID:${msg.id} 进度:${msg.index}/${msg.size}）`
      }else if(msg.type === this.MSGTYPE.FIN){
        return `${isSender?"发送FINISH":"[接收FINISH]"}（ID:${msg.id} `
      }else if(msg.type === this.MSGTYPE.ABORT){
        return `${isSender?"发送中断":"[接收中断]"}`
      }
    },
    getBackgroundClass(msg){
      if(!msg || Object.keys(msg).length===0) return 'bg-success';
      if(msg.type === this.MSGTYPE.TEXT){
        return 'bg-primary'
      }else if(msg.type === this.MSGTYPE.ACK){
        return 'bg-warning'
      }else if(msg.type === this.MSGTYPE.FIN){
        return 'bg-success'
      }else if(msg.type === this.MSGTYPE.ABORT){
        return 'bg-danger'
      }
    },
    addSenderLog(text){
      this.logUpdateText('1', text)
    },
    addReceiverLog(text){
      this.logUpdateText('2', text)
    },
    addSysLog(text){
      this.logUpdateText('9', text)
    },
    logUpdateText(type, text){
      //不合理的请求直接返回
      if((text&&!type)) return false
      let needUpdate = this.logger.type===type || this.logger.type==='0'
      let array = this.logger.dataArray[type]
      if(text && array){
        array.push([new Date().getTime(),text,type])
        if(array.length > this.logger.maxSize){
          array = array.slice(1)
        }
      }
      if(needUpdate){
        if(text){
          this.logger.text = this.logger.text + `\n${this.logger.typeArray[type]}:${text}`
        }else{
          if(type === '0'){
            array = this.logger.dataArray[1].concat(this.logger.dataArray[2]).concat(this.logger.dataArray[9])
            array = array.sort((a,b)=>(a[0]>b[0]?1:((a[0]<b[0])?-1:0)))
          }else{
            array = this.logger.dataArray[type]
          }
          this.logger.text = array.map(a=>`${this.logger.typeArray[a[2]]}:${a[1]}`).join('\n')
        }
      }
    },
    uploadLog(silence){
      if(silence){
        this.$axios.post(postTaskLogUrl, {content: JSON.stringify(this.logger.dataArray)}).then(res=>{
          this.logger.index = res.data.index
        })
      }else{
        this.$confirm('上传当前日志?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios.post(postTaskLogUrl, {content: JSON.stringify(this.logger.dataArray)}).then(res=>{
            this.logger.index = res.data.index
            this.$message.success(`日志上传成功，编号${res.data.index}`)
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    },
    openLog(){
      window.open(`${document.location.origin}/${process.env.VUE_APP_NAME}/hllog`)
    },
    initCamera(){
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ video: { width: 2560, height: 1440 } }).then(function(stream) {
          video.srcObject = stream;
          video.play();
        });
      }
      let html5QrcodeScanner = new Html5QrcodeScanner(
          "qr-reader", { fps: 5, qrbox: {width: 4096, height: 2160}});
          // "qr-reader", { fps: 10, qrbox: 450 }, /* verbose= */ false);
      html5QrcodeScanner.render(this.onScanSuccess);
    },
    onScanSuccess(qrCodeMessage) {
      if(typeof JSON.parse(qrCodeMessage) !== 'object' || qrCodeMessage === previousCode) return false
      this.output = qrCodeMessage;
      this.addReceiverLog(`相机读取二维码\t${qrCodeMessage}\t前一个二维码${previousCode}`)
      previousCode = qrCodeMessage;
    },
  },
  mounted() {
    loadScript(`/${process.env.VUE_APP_NAME}/js/html5-qrcode.min.js`)
        .then(() => {
          // Script is loaded, do something
          this.initCamera()
        })
        .catch(() => {
          // Failed to fetch script
          this.$message.error('初始化相机失败')
        });
  }
}
</script>

<style scoped>

.el-row{
  width: 100%;
}
.grid-content{
  min-height: 500px;
  border-right: 5px solid gray;
}

.bg-purple {
  background: #d3dce6;
}

.bg-success{
  background: #67C23A;
}

.bg-primary{
  background: #409EFF;
}

.bg-danger{
  background: #F56C6C;
}

.bg-warning{
  background: #E6A23C;
}

.bg-info{
  background: #909399;
}

.sub-title{
  font-size: 2em;
  line-height: 2.0;
  margin: 0 auto;
  display: block;
  text-align: center;
  background: #15AD66;
  margin-bottom: 5px;
}

.sub-icon{
  font-size: 1.1em;
  margin-left: 5px;
}

.sub-icon:hover{
  color: #5cb6ff;
}

.sub-title-log span{
  background: #15AD66;
  margin: 0 auto;
  display: block;
  margin-top: 5px;
  font-size: 2em;
  text-align: center;
}

.sub-title-log textarea{
  width: 95%;
  min-height: 400px;
  background-color: black;
  color: white;
}

.config-title{
  display: block;
  text-align: center;
  font-size: 1.3em;
  margin-bottom: 5px;
}

.configBtn{
  display: block;
  margin: 0 auto;
}

.data-description{
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin: 5px auto;
  padding: 3px;
  background-color: #99a9bf;
}

.data-description span{
  font-size: 1.2em;
  margin-left: 10px;
}

.data-description .el-tag{
  margin-left: 10px;
}

#qr-container{
  border: 1px solid #eee;
  min-width: 200px;
  min-height: 200px;
  padding: 5px;
}

@keyframes pmove {
  0% {transform: translateX(0px)}
  100% {transform: translateX(10px)}
}

</style>